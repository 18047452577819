@import 'Styles/includes';

/**
*
* CardFact
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.CardFact {
    $root: &;

    position: relative;
    padding: 24px;
    border-radius: 4px;
    color: $text;
    background-color: $gray-10;
    margin: 0 auto;

    @include media(md) {
        width: 756px;
        padding: 36px 64px;
    }

    // Old modifiers
    &--BackgroundLightYellow {
        background-color: rgba($yellow, 0.1);

        &#{$root}--Wide {
            background-color: transparent;
        }

        &::before {
            background-color: rgba($yellow, 0.1) !important;
        }
    }

    &--BackgroundLightGray {
        background-color: rgba($gray-10, 0.5);

        &#{$root}--Wide {
            background-color: transparent;
        }

        &::before {
            background-color: rgba($gray-10, 0.05) !important;
        }
    }

    &--BackgroundLightGreen {
        color: $text;
        background-color: rgba($green, 0.1);

        &#{$root}--Wide {
            background-color: transparent;
        }

        &::before {
            background-color: rgba($green, 0.1) !important;
        }
    }

    &--BackgroundLightBlue {
        background-color: rgba($blue, 0.1);

        &#{$root}--Wide {
            background-color: transparent;
        }

        &::before {
            background-color: rgba($blue, 0.1) !important;
        }
    }

    &--BackgroundLightPurple {
        background-color: rgba($purple, 0.1);

        &#{$root}--Wide {
            background-color: transparent;
        }

        &::before {
            background-color: rgba($purple, 0.1) !important;
        }
    }

    &--BackgroundLightRed {
        background-color: rgba($red, 0.1);

        &#{$root}--Wide {
            background-color: transparent;
        }

        &::before {
            background-color: rgba($red, 0.1);
        }
    }

    // Adds background color, must be placed after above
    @include sensus-bg-modifiers(true, true);

    &--Wide {
        width: 100%;
        padding: 24px;

        @include media(md) {
            padding: 42px 24px;
        }

        &::before {
            display: block !important;
        }
    }

    &::before {
        content: '';
        display: none;
        position: absolute;
        width: 100vw;
        height: 100%;
        background-color: inherit;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
    }

    &__Wrap {
        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }

        #{$root}--HasIcon & {
            @include media(md) {
                display: flex;
                width: 100%;
                flex-direction: row;
                align-items: stretch;
            }
        }
    }

    &__IconWrap {
        display: none;

        #{$root}--HasIcon & {
            @include media(md) {
                display: flex;
                align-items: center;
                width: 25%;
                padding-left: 64px;
                margin-left: 44px;
                border-left: 1px solid rgba($black, 0.15);
            }
        }
    }

    &__Icon {
        font-size: 6rem;
        display: block;

        #{$root}--RedIcon & {
            color: $red-icon-color;
        }

        &--Small {
            @include media(md) {
                display: none !important;
            }
        }

        @include media(md) {
            font-size: 6.2rem;
        }
    }
}
